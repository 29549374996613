import React from 'react';
import { BrandingIconCard, NamedLink, ResponsiveImage } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import { createResourceLocatorString } from '../../util/routes';
import { DEAL_LISTING_TYPE, PRODUCT_LISTING_TYPE, SERVICE_LISTING_TYPE } from '../../util/types';
import { createSlug } from '../../util/urlHelpers';
import css from './DealCard.module.css';
import classNames from 'classnames';

const ProductCard = (props) => {
  // Destructure necessary fields from listing object
  const {
    listing,
    currentUser,
    toggleFavorite,
    history,
    routeConfiguration: routes,
  } = props;

  const { author, attributes, images = [], id } = listing || {};
  const { title, price, publicData } = attributes || {};
  const { displayName: authorName } = author?.attributes?.profile || {};

  const {
    listingType,
  } = publicData || {};

  const isBooking = listingType === SERVICE_LISTING_TYPE;

  // Determine the correct wishlist type based on listingType
  const wishlistTypeKey =
    listingType === SERVICE_LISTING_TYPE ? 'wishlist_service_ids'
      : listingType === DEAL_LISTING_TYPE ? 'wishlist_deal_ids'
        : listingType === PRODUCT_LISTING_TYPE ? 'wishlist_product_ids'
          : null;

  const wishlist = currentUser?.attributes?.profile?.publicData?.[wishlistTypeKey] || [];

  // Generate slug for the listing page URL
  const listingId = listing?.id?.uuid;
  const listingSlug = createSlug(title);

  // Check if this listing is already marked as a favorite
  const isFavorite = wishlist.includes(listingId);

  /**
   * Handles the click event for adding/removing a listing to/from favorites.
   */
  const handleFavoriteClick = () => {
    if (currentUser === null) {
      history.push(createResourceLocatorString('LoginPage', routes, {}, {}));
    } else {
      toggleFavorite(listingId, listingType, currentUser, listing?.author?.id, title, images);
    }
  };
  const arabicLanguage = typeof window !== 'undefined' && localStorage.getItem("language") === "Arabic";
  return (
    <div className={classNames(css.productCardWrapper, arabicLanguage && css.arabicDirection)}>
      <NamedLink name="ListingPage" params={{ id: listingId, slug: listingSlug }}>
        <div className={css.root}>
          <div className={css.jobCard}>
            <div className={css.imageWrapper}>
              <ResponsiveImage
                alt={title}
                image={images[0]}
                variants={['listing-card', 'listing-card-2x']}
                width={'100%'}
                height={300}
              />

              <span
                className={isFavorite ? css.likeIconWrapper : css.wishListIcon}
                onClick={e => {
                  e.preventDefault();
                  handleFavoriteClick();
                }}
              >
                <BrandingIconCard type="heart" />
              </span>
            </div>
            <div className={css.detailsWrapper}>
              <div>
                <h2 className={css.dealTitle}>{title}</h2>
                <p className={css.authorName}>{authorName}</p>

                <div className={css.priceWrapper}>
                  <span className={css.dealPrice}>
                    {price?.amount
                      ? price?.currency + (price?.amount / 100)
                      : `0 ${price?.currency}`}
                  </span>
                </div>

                {/* <div className={css.reviewDetails}>
              <BrandingIconCard type="star_2" />
              <BrandingIconCard type="star_2" />
              <BrandingIconCard type="star_2" />
              <BrandingIconCard type="star_2" />
              <BrandingIconCard type="star_2" />
              <span className={css.reviewNumber}>0</span>
            </div> */}
              </div>

              <div className={css.buttonWrapper}>
                <button
                  type="button"
                  className={css.button}
                >
                  <FormattedMessage id={isBooking ? 'SearchPage.book' : 'SearchPage.buy'} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </NamedLink>
    </div>
  );
};

export default ProductCard;
